import {
  getStyleConfig,
  registerDefaultWidgets,
  registerWidgetStyle,
  registerWidgetStyleOption,
  setDMEditorCallback,
  setDMEditorConfig,
} from "dmeditor";
import { preDefinedStyles } from "./dme-config/dme_predefined_styles";
import { categorizedStyles } from "./dme-config/dme_categoried_styles";
import registerWeatherWidget from "./widgets/weather";
import { styleConfig } from "./styleConfig";
import { BrowseImage } from "./dme-config/browse-image";

export const dmeInit = () => {
  registerDefaultWidgets();
  registerWeatherWidget();

  const defaultStyleWidets = Object.keys(preDefinedStyles);
  const defaultStyleConfig: any = {};
  for (const widget of defaultStyleWidets) {
    const option = (preDefinedStyles as any)[widget];
    registerWidgetStyleOption(widget, option);
    defaultStyleConfig[widget] = { _: "_default" };
  }

  defaultStyleConfig["button"] = { type: "primary" };

  for (const widget of Object.keys(categorizedStyles)) {
    for (const style of (categorizedStyles as any)[widget]) {
      registerWidgetStyle(widget, style);
    }
  }

  setDMEditorConfig({
    general: {
      projectStyles: {
        default: `background: white;
        
        `,
      },
    },
    editor: {
      defaultTheme: "default",
      favouriteWidgets: ["text", "button"],
      categories: [{ identifier: "sample", name: "Sample" }],
      enableEditControl: true,
      colors: {
        text: [
          { color: "#326f4b", name: "Green" },
          { color: "#A61C00", name: "Dark red" },
          { color: "#FF0000", name: "Red" },
          { color: "#1155CC", name: "Blue" },
          { color: "#9900FF", name: "Dark purple" },
          { color: "#FF00FF", name: "Light purple" },
          { color: "#ffffff", name: "White" },
        ],
        border: [
          { color: "#000000" },
          { color: "#333333" },
          { color: "#666666" },
          { color: "#999999" },
          { color: "#cccccc" },
          { color: "#ffffff" },
        ],
        background: [
          { color: "#F3F3F3", name: "Light white" },
          { color: "#D9EAD3", name: "Light green" },
          { color: "#F4CCCC", name: "Light red" },
          { color: "#FFF2CC", name: "Light yellow" },
          { color: "#198754", name: "Green" },
          { color: "#A61C00", name: "Dark red" },
          { color: "#434343", name: "Dark gray" },
        ],
      },
      defaultStyle: defaultStyleConfig,
      zIndex: 2000,
      ui: {
        "bg-editarea": "#666666",
      },
      configStyleSettings: styleConfig,
    },
    widgets: {
      heading: {},
    },
    plugins: {},
  });
};

setDMEditorCallback({
  browseImage: BrowseImage,
});
