import { nanoid } from "nanoid";

import { generalSettings } from "dmeditor";

export const weatherWidgetDef = {
  type: "weather",
  name: "Weather",
  category: "sample",
  icon: "A",
  settings: [
    {
      name: "City",
      settingComponent: "input",
      property: ".city",
      parameters: {
        updateOnUnfocus: true,
      },
      description: "Input a city with country",
    },
  ],
  events: {
    createBlock: () => ({
      id: nanoid(),
      type: "weather",
      data: {
        city: "New York, USA",
        settings: {
          general: {
            padding: [0, 10, 0, 10],
            background: "#778F9C",
          },
        },
      },
    }),
  },
};
